#reset-link {
    margin-top: 190px;
}

.light {
    text-align: center;
    font-weight: 500;
}

.standalone-link {
    margin-top: 15px;
}

.no-margin {
    margin: 0;
}