#host-lobby h1 {
    text-align: center;
    margin-bottom: 20px;
}

#host-lobby h2 {
    text-align: center;
    margin: 40px 0 15px 0;
}

#players-list {
    margin-bottom: 25px;
    width: 800px;
    height: 500px;
    background-color: #dad7cd;
    border-radius: 5px;
    overflow-y: scroll;
}

#players-list p {
    font-size: 20px;
    font-weight: 500;
}

#players-list::-webkit-scrollbar {
    display: none;
}

.player-name{
    margin: 5px 0 1px 0;
    color: #333333;
}

.button {
    cursor: pointer;
    background-color: #344e41;
    border: none;
    margin: 15px;
    width: 225px;
    height: 3rem;
    border-radius: 5px;
    font-weight: 600;
    font-size: 20px;
}

.button:hover {
    filter: brightness(110%);
}

@media only screen and (max-width: 1000px) {
    #players-list {
        width: 500px;
        height: 550px;
    }
}

@media only screen and (max-width: 500px) {
    #host-lobby h2 {
        font-size: 18px;
    }

    #host-lobby h1 {
        margin: 10px 0 7px 0
    }

    #players-list {
        width: 250px;
        height: 400px;
    }

    .button {
        width: 150px;
        height: 2rem;
        font-size: 16px;
    }
}

