#create-quiz {
    padding: 0 20px 0 20px;
}

#back-button {
    position: absolute;
    top: 60px;
    left: 20px;
    width: 35px;
    height: 35px;
    margin: 0;
    cursor: pointer;
}

#create-quiz label {
    margin-right: 8px;
}
 
#quiz-details {
    margin: 15px 0;
}

#create-quiz input {
    margin: 1px 20px 10px 0;
    color: black;
    border: lightgray;
    border-radius: 3px;
    height: 1.4rem;
}

.smaller {
    width: 40px;
}

.form-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.buttons button {
    cursor: pointer;
    background-color: #344e41;
    border: none;
    margin: 15px;
    width: 125px;
    height: 2rem;
    border-radius: 15px;
    font-weight: 600;
}

.buttons button:hover {
    filter: brightness(110%);
}



@media only screen and (max-width: 450px) {
    #quiz-details {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        padding-left: 20px;
   
    }

    #quiz-details input {
        margin-bottom: 5px;
    }

    .buttons button {
        width: 100px;
        height: 2.2rem;
        margin: 8px;
        font-size: 11px;
    }
}