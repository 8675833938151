#question-form {
    display: flex;
    justify-content: center;
    margin: 20px;
    border-radius: 8px;
    padding: 15px;
    background-color: #dad7cd;
}
 
#question-form form {
     display: flex;
     flex-direction: column;
 }
 
#question-form label {
     margin: 5px 0 5px 1px;
     font-size: 14px;
     font-weight: 500;
     color: black;
}
 
#question-form input {
     border-radius: 5px;
     background-color: white;
     color: black;
     margin-bottom: 5px;
     border: 1px solid lightgray;
     height: 1.5rem;
}
 
#question-form select {
     background-color: white;
     color: black;
     border: 1px solid lightgray;
     height: 1.7rem;
     border-radius: 5px;
     margin-bottom: 15px;
}
 
#question-form select:focus {
     outline: none;
}
 
.same-row {
     margin-top: 5px;
     display: flex;
     justify-content: space-between;
}
 
.column {
     display: flex;
     flex-direction: column;
}

.last-row {
    margin-top: 5px;
}

#correct-answer {
    width: 40px;
}

#question-type {
    width: 145px;
}

#question {
    width: 375px;
}

#close-button {
    position: absolute;
    transform: translate(198px, -10px);
    width: 25px;
    height: 25px;
    cursor: pointer;
}

@media only screen and (max-width: 450px) {
    #question {
        width: 200px;
    }

    #question-type {
        width: 120px;
    }

    #answer-one, #answer-two, #answer-three, #answer-four {
        width: 80px;
    }

    #close-button {
        transform: translate(112px, -12px)
    }
}

 