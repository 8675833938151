#player-lobby h1 {
    text-align: center;
    margin-bottom: 15px;
}

#player-lobby p {
    text-align: center;
    margin-bottom: 25px;
}

.spinner-container {
    margin-bottom: 25px;
    width: 75px;
    height: 75px;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #ffffff; 
    border-top: 10px solid #344E41; 
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

@media only screen and (max-width: 450px) {
    #player-lobby h1 {
        font-size: 18px;
    }

    #player-lobby p {
        font-size: 12px;
    }
}