.logo {
    position: fixed;
    top: 0;
    height: 48px;
    width: 100%;
    z-index: 10;
    background-color: #588157;
}

.logo-container {
    position: relative;
    top: 3px;
}

@media only screen and (max-width: 750px) {
   .logo {
        display: flex;
        justify-content: center;
    }
}