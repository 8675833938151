#player-game h1 {
    text-align: center;
}

.grid-container {
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    row-gap: 15px;
    column-gap: 25px;
}

.question-message-container {
    margin: 10px 30px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 300px;
}

.data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

#short-answer, #correct-message, #incorrect-message {
    justify-content: center;
}

#multiple-choice {
    justify-content: flex-start;
}

#short-answer h2, #multiple-choice h2 {
    text-align: center;
    color: #344e41
}

#multiple-choice-answers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

#multiple-choice-answers div {
    cursor: pointer;
    width: 190px;
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a3b18a;
    border-radius: 5px;
    padding: 6px;
}

#multiple-choice-answers div:hover {
    background-color: #344e41;
}

#multiple-choice-answers div h4 {
    text-align: center;
    color: white;
}

#correct-message h2, #correct-message h4 {
    color: #344e41;
}

#correct-message h2 {
    margin-bottom: 0;
}

#correct-message h4 {
    margin-top: 10px;
}

#incorrect-message h2 {
    color: rgb(113, 7, 7);
}

#short-answer input{
    width: 350px;
    height: 1.5rem;
    border-radius: 5px;
    background-color: white;
    color: black;
    margin-bottom: 5px;
    border: 1px solid lightgray;
}

#short-answer button {
    cursor: pointer;
    background-color: #344e41;
    border: none;
    margin: 15px;
    width: 150px;
    height: 2.5rem;
    border-radius: 20px;
    font-weight: 600;
}

#short-answer button:hover {
    filter: brightness(110%);
}

#score {
    position: relative;
    bottom: 210px;
    left: 300px;
    border: 2px solid #344e41;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: -125px;
}

#question-number {
    position: relative;
    bottom: 305px;
    left: 300px;
    margin-bottom: -100px;
}

#score h2 {
    margin-bottom: 5px;
}

#score h1 {
    margin-top: 0;
}

@media only screen and (max-width: 850px) {
    .grid-container {
        margin-top: 5px;
        grid-template-columns: repeat(6, 1fr);
        gap: 15px;
    }

    .question-message-container {
        margin: 10px 0 20px 0;
    }

    #score {
        position: static;
        height: 100px;
        margin-top: 15px;
        border: none;
    }
    
    #question-number {
        position: relative;
        bottom: 55px;
        left: 0;
        height: 50px;
        border-bottom: 2px solid #344e41;
    }
}

@media only screen and (max-width: 450px) {
    .grid-container {
        margin-top: 5px;
        grid-template-columns: repeat(6, 1fr);
        gap: 10px;
    }

    #short-answer input {
        width: 250px;
    }

    #short-answer h2, #multiple-choice h2 {
        font-size: 20px; 
    }

    .question-message-container {
        width: 350px;
        height: 250px;
        margin: 0 0 20px 0;
    }

    #multiple-choice {
        justify-content: center;
    }

    #multiple-choice-answers {
        gap: 10px;
    }

    #multiple-choice-answers div h4 {
        font-size: 10px;
    }

    #multiple-choice-answers div {
        width: 126px;
        height: 60px;
    }

    #score {
        position: static;
        margin-top: 10px;
        height: 100px;
        border: none;
    }
    
    #question-number {
        position: relative;
        bottom: 55px;
        left: 0;
    }  
}

@media only screen and (max-width: 375px) {
    #short-answer h2, #multiple-choice h2 {
        width: 200px;
        font-size: 14px;
    }
}