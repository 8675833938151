.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#score-message h1 {
    margin: 0;
}

#final-score {
    margin-top: 25px;
    border: 2px solid #344e41;
    border-radius: 5px;
    width: 140px;
    height: 140px;
}

#score-message h1, #score-message h3 {
    text-align: center;
}

#final-score h1 {
    margin: 5px;
}

#score-message h2 {
    margin: 0;
}

#finished-game button {
    cursor: pointer;
    background-color: #344e41;
    border: none;
    margin: 35px 0 0 0;
    width: 140px;
    height: 2.5rem;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
}

#finished-game button:hover {
    filter: brightness(110%);
}

@media only screen and (max-width: 450px) {
    #score-message h1 {
        font-size: 22px;
    }

    #final-score h1 {
        font-size: 32px;
    }
}